import React, {useContext, useEffect, useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import IconEdit from "../../components/Icons/IconEdit";
import IconHandle from "../../components/Icons/IconHandle";
import IconChevronUp from "../../components/Icons/IconChevronUp";
import IconChevronDown from "../../components/Icons/IconChevronDown";
import MenuProducts from "./MenuProducts.js"
import config from "../../config.json";


function MenuItem({
                     children,
                     controls,
                     onSelect,
                     open = false,
                     selected,
                     id,
                     categoryId,
                     setIsDrawerOpen,
                     setIsCategory,
                     setIsProduct,
                     setCategoryId,
                     setProductId,
                     loadProducts,
                     setLoadProducts,
                     ...props
                  }) {
   const [isOpen, setIsOpen] = useState(open);
   const [products, setProducts] = useState([]);
   const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id});

   useEffect(() => setIsOpen(open), [open]);

   useEffect(() => {
      if (loadProducts) {
         fetchCategoryProducts();
         setLoadProducts(false);
      }
   });

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? "100" : "auto",
   };

   const selectable = onSelect !== undefined;

   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)

   const productsUrl = `${config.API_URL}/tenants/${decoded.tenant_id}/categories/${id}/products`
   const categoryUrl = `${config.API_URL}/tenants/${decoded.tenant_id}/categories/${id}`

   const [checked, setChecked] = useState(false);
   useEffect(() => setChecked(selected), [selected]);

   const putCategory = async (is_active) => {
      let data = {'is_active': is_active}
      axios.put(categoryUrl, data, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
         .then(response => {
            console.log(response.status);
            if (response.status == 200) {
               setChecked(is_active)
               console.log('category: ' + id + ', checked: ' + is_active);
            }
         })
         .catch(error => {
            console.log(error.response)
         });
   };

      const fetchCategoryProducts = async () => {
      console.log('{ CategoryId - ' + id)
      // setCategoryId(id)
      const {data} = await axios.get(productsUrl, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      setProducts(data);
      console.log('--- products ---');
      console.log(data);
      console.log('--- end products ---');
   };

   const fetchProducts = async () => {
      console.log('CategoryId - ' + id)
      setCategoryId(id)
      const {data} = await axios.get(productsUrl, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      setProducts(data);
      console.log('--- products ---');
      console.log(data);
      console.log('--- end products ---');
   };

   function handleChange(e) {
      putCategory(e.target.checked);
   }

   const handleClick = () => {
      console.log('Click happened');
      setIsOpen(!isOpen)
      fetchProducts()
   };


   return (
      <div ref={setNodeRef} className="" style={style} {...props}>
         <div
            className={"card bg-base-100 shadow-xl py-2 border-solid border-2" + (isDragging ? " border-primary" : " border-base-100")}>
            <div className="flex items-center my-0 space-x-4 px-4">

               <div className="inline-block opacity-80"
                    style={{cursor: 'ns-resize'}}
                    color="link"
                    {...attributes} {...listeners}
               >
                  <IconHandle addClass="w-6"/>
               </div>

               <img id={"category_" + id + "_img"} className="mask mask-squircle w-10"
                    src={props.item.image}>
               </img>

               <div id={"category_" + id + "_name"} className="flex-auto">{children}</div>
               {selectable && (
                  <input
                     id={"category_" + id + "_status"}
                     className="toggle toggle-sm"
                     type="checkbox"
                     checked={checked}
                     onChange={(e) => handleChange(e)}
                  />
               )}

               {controls}

               <button
                  className="flex items-center cursor-pointer px-4 py-4"
                  onClick={() => {
                     setIsDrawerOpen(true);
                     setIsProduct(false);
                     setIsCategory(true)
                     setCategoryId(id)
                  }}>
                  <IconEdit addClass="w-4 opacity-80"/>
               </button>

               <button color="link" onClick={handleClick}>
                  {isOpen ? <IconChevronUp addClass="w-5 opacity-80"/> :
                     <IconChevronDown addClass="w-5 opacity-80"/>}
               </button>

            </div>
         </div>

         <div className={isOpen ? 'block' : 'hidden'}>
            <MenuProducts
               id={id}
               items={products}
               categoryId={id}
               productItems={props.productItems}
               setIsDrawerOpen={setIsDrawerOpen}
               setIsCategory={setIsCategory}
               setIsProduct={setIsProduct}
               setCategoryId={setCategoryId}
               setProductId={setProductId}/>
         </div>

      </div>
   );
}

export default MenuItem;
