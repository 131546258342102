import React, {useContext, useState, useEffect} from 'react';
import NavBar from './NavBar/NavBar';
import Sidebar from './Sidebar';
import {Outlet} from "react-router-dom";
import jwtDecode from "jwt-decode";
import AuthContext from '../../store/auth-context';
import Toast from "./Toast";


const Layout = ({children}) => {
   const authCtx = useContext(AuthContext)
   const [userNameDetails, setUserNameDetails] = useState("")
   const [userEmailDetails, setUserEmailDetails] = useState("")
   const [userSplitName, setUserSplitName] = useState("")

   useEffect(() => {
      const decoded = jwtDecode(authCtx.token)
      const fullName = decoded.name.split(' ');
      const setSplitName = fullName.shift().charAt(0) + fullName.pop().charAt(0)
      setUserNameDetails(decoded.name)
      setUserEmailDetails(decoded.email)
      setUserSplitName(setSplitName)
   }, [authCtx])

   return (
      <div className="bg-base-100 drawer drawer-mobile">
         <input id="drawer" type="checkbox" className="drawer-toggle"></input>
         <div className="drawer-content">
            <NavBar
               name={userNameDetails}
               email={userEmailDetails}
               splitName={userSplitName}
            />
            <Outlet/>
            <main>{children}</main>
            <Toast/>
         </div>
         <Sidebar/>
      </div>
   );
};

export default Layout;