import React, {useState, useRef, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import axios from "axios";
import AuthContext from '../../store/auth-context';
import config from "../../config.json";

const AuthForm = () => {
   const navigate = useNavigate();
   const emailInputRef = useRef();
   const passwordInputRef = useRef();

   const authCtx = useContext(AuthContext);

   const [isLogin, setIsLogin] = useState(true);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');

   const submitHandler = (event) => {
      event.preventDefault();

      const enteredEmail = emailInputRef.current.value;
      const enteredPassword = passwordInputRef.current.value;

      // optional: Add validation
      setIsLoading(true);
      const params = new URLSearchParams();
      params.append('username', enteredEmail);
      params.append('password', enteredPassword);
      axios
         .post(`${config.API_URL}/login/access-token`, params, {
            withCredentials: true
         })
         .then(res => {
            setIsLoading(false);
            const token = res.data.access_token
            const decodeJwt = JSON.parse(atob(token.split('.')[1]));
            const unixTimestamp = decodeJwt["exp"]
            const milliseconds = unixTimestamp * 1000
            const expirationTime = new Date(milliseconds)
            authCtx.login(token, expirationTime.toISOString());
            navigate("/dashboard");
            console.log(res.data)
            console.log(expirationTime.toISOString())
         })
         .catch(err => {
            setIsLoading(false);
            console.log(err)
            if (err.response.data) {
               console.log(err.response.data.detail)
               setError(err.response.data.detail)
            }
         })
   }

   return (
      <div data-theme="default"
         className="lg:h-screen flex flex-col space-y-12 justify-center lg:bg-gradient-to-t from-[#E5E1E1] to-[#E0DDDD]">
         <div className="lg:flex xl:shadow-lg xl:w-1/2 lg:w-2/3  mx-auto lg:h-4/6 w-full">
            <div
               className="lg:w-7/12 bg-primary lg:rounded-l-lg relative bg-[#6d00e7] lg:bg-contain bg-cover bg-no-repeat bg-bottom flex-col justify-between flex">
               <img className="w-3/5 p-12" src="/menunow-white.png" alt=""/>
               <div className="flex flex-col justify-end p-12 h-full space-y-4">
                  <div className="text-5xl font-bold text-white">Σύνδεση</div>
                  <div className="text-3xl text-white">Καλωσορίσατε</div>
                  <div className="text-white">Για επιπλέον υποστήριξη παρακαλούμε επικοινωνήστε μαζί μας στο
                     info@webarch.gr
                  </div>
               </div>
            </div>
            <div
               className="lg:w-5/12 bg-white lg:rounded-r-lg flex flex-col space-y-12 p-12 lg:items-center justify-center">
               <div className="text-primary">Εισάγετε τα στοιχεία σας για να συνεχίσετε</div>
               <form onSubmit={submitHandler} className="w-full">
                  <span className="text-error">{error}</span>
                  <div className="mt-8">
                     <input type='email' id='email' placeholder="Email"
                            className="border-b-2 bg-transparent border-primary focus:outline-none w-full" required
                            ref={emailInputRef}/>
                  </div>
                  <div className="mt-8">
                     <input type='password' placeholder="Κωδικός" id='password'
                            className="bg-transparent border-b-2 border-primary w-full focus:outline-none" required
                            ref={passwordInputRef}/>
                  </div>
                  <div className="pt-4 text-right">
                     <Link to="/forgot-password" className="text-xs font-display font-semibold text-primary cursor-pointer">
                        Ξεχάσατε τον κωδικό;</Link>
                  </div>
                  <div className="mt-10 flex justify-end">
                     {!isLoading && (<button
                        className="bg-primary text-white font-bold px-8 py-2 rounded-full text-xl">Σύνδεση</button>)}
                     {isLoading && <p>Sending request...</p>}
                  </div>
               </form>
            </div>
         </div>

      </div>
   );
};

export default AuthForm;