// src/components/Sidebar.js

import React from 'react';
import {Link, NavLink} from 'react-router-dom'
import IconDashboard from "../Icons/IconDashboard";
import IconMenu from "../Icons/IconMenu";
import IconFeedbacks from "../Icons/IconFeedbacks";
import IconTranslations from "../Icons/IconTranslations";
import IconProfile from "../Icons/IconProfile";
import IconSettings from "../Icons/IconSettings";
import config from "../../config.json";


const Sidebar = () => {

   return (
      <div className="drawer-side">
         <label htmlFor="drawer" className="drawer-overlay"></label>
         <aside className="bg-base-200 w-80">
            <div
               className="z-20 bg-base-200 bg-opacity-90 backdrop-blur sticky top-0 items-center gap-2 px-4 py-2 hidden lg:flex">
               <Link to="/dashboard" className="flex-0 btn btn-ghost px-2">
                  <div className="flex items-end space-x-1">
                    <img className="w-[250px]" src="/menunow.png" alt=""/>
                  </div>
               </Link>
               {/*<div className="font-mono text-xs text-opacity-50 pt-1">{config.VERSION}</div>*/}
            </div>
            <div className="h-4"></div>
            <ul className="menu menu-compact flex flex-col p-0 px-4">
               <li>
                  <NavLink to="/dashboard" className="flex gap-4">
                        <span className="flex-none">
                           <IconDashboard/>
                        </span>
                     <span className="flex-1">Πίνακας Ελέγχου</span>
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/menu" className="flex gap-4">
                        <span className="flex-none">
                           <IconMenu/>
                        </span>
                     <span className="flex-1">Μενού</span>
                  </NavLink>
               </li>
               {/*<li>*/}
               {/*   <NavLink to="/products" className="flex gap-4">*/}
               {/*         <span className="flex-none">*/}
               {/*            <IconMenu/>*/}
               {/*         </span>*/}
               {/*      <span className="flex-1">Προϊόντα</span>*/}
               {/*   </NavLink>*/}
               {/*</li>*/}
               {/*<li>*/}
               {/*   <NavLink to="/categories" className="flex gap-4">*/}
               {/*         <span className="flex-none">*/}
               {/*            <IconCategories/>*/}
               {/*         </span>*/}
               {/*      <span className="flex-1">Κατηγορίες</span>*/}
               {/*   </NavLink>*/}
               {/*</li>*/}
               <li>
                  <NavLink to="/feedbacks" className="flex gap-4">
                        <span className="flex-none">
                           <IconFeedbacks/>
                        </span>
                     <span className="flex-1">Αξιολογήσεις</span>
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/translations" className="flex gap-4">
                        <span className="flex-none">
                           <IconTranslations/>
                        </span>
                     <span className="flex-1">Μεταφράσεις</span>
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/profile" className="flex gap-4">
                        <span className="flex-none">
                           <IconProfile/>
                        </span>
                     <span className="flex-1">Προφίλ</span>
                  </NavLink>
               </li>
               <li>
                  <NavLink to="/settings" className="flex gap-4">
                        <span className="flex-none">
                           <IconSettings/>
                        </span>
                     <span className="flex-1">Ρυθμίσεις</span>
                  </NavLink>
               </li>
            </ul>
         </aside>
      </div>
   );
};

export default Sidebar;