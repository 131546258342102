import React, {useContext, useEffect, useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import IconEdit from "../../components/Icons/IconEdit";
import IconHandle from "../../components/Icons/IconHandle";
import config from "../../config.json";

function ReorderableItem({
                            children,
                            controls,
                            onSelect,
                            open = false,
                            selected,
                            id,
                            setIsDrawerOpen,
                            setIsCategory,
                            setIsProduct,
                            setCategoryId,
                            setProductId,
                            ...props
                         }) {
   const [isOpen, setIsOpen] = useState(open);
   const [products, setProducts] = useState([]);
   const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id});

   useEffect(() => setIsOpen(open), [open]);

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? "100" : "auto",
   };

   const selectable = onSelect !== undefined;

   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)

   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/products/${id}`

   const [checked, setChecked] = useState(false);
   useEffect(() => setChecked(selected), [selected]);

   const putProduct = async (is_active) => {
      let data = {'is_active': is_active}
      axios.put(url, data, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
         .then(response => {
            console.log(response.status);
            if (response.status == 200) {
               setChecked(is_active)
               console.log('product: ' + id + ', checked: ' + is_active);
            }
         })
         .catch(error => {
            console.log(error.response)
         });
   };

   function handleChange(e) {
      putProduct(e.target.checked);
   }

   const handleClickEdit = () => {
      setIsOpen(!isOpen)
   };

   return (
      <div ref={setNodeRef} className="" style={style} {...props}>
         <div
            className={"card bg-base-100 shadow-xl py-2 border-solid border-2" + (isDragging ? " border-primary" : " border-base-100")}>
            <div className="flex items-center my-0 space-x-4 px-4">

               <div className="inline-block opacity-80"
                    style={{cursor: 'ns-resize'}}
                    color="link"
                    {...attributes} {...listeners}
               >
                  <IconHandle addClass="w-6"/>
               </div>

               <img id={"product_" + id + "_img"} className="mask mask-squircle w-10"
                    src={props.item.image}></img>

               <div id={"product_" + id + "_name"} className="flex-auto">{children}</div>
               {selectable && (
                  <input
                     id={"product_" + id + "_status"}
                     className="toggle toggle-sm"
                     type="checkbox"
                     checked={checked}
                     onChange={(e) => handleChange(e)}
                  />
               )}

               {controls}

               <button
                  className="flex items-center cursor-pointer px-4 py-4"
                  onClick={() => {
                     setIsDrawerOpen(true);
                     setIsProduct(true);
                     setIsCategory(false)
                     setProductId(id)
                     console.log("clicked product id: " + id)
                     handleClickEdit()
                  }}>
                  <IconEdit addClass="w-4 opacity-80"/>
               </button>

            </div>
         </div>

      </div>
   );
}

export default ReorderableItem;
