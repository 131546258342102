// src/components/NavBarSearch.js

import React from 'react';
import IconSearch from "../../Icons/IconSearch";


const NavBarSearch = () => {
   return (
      <div className="hidden w-full max-w-sm lg:flex">
         <label className="searchbox relative mx-3 w-full">
            <IconSearch/>
            <div id="typeahead-0.xu47853jhj-typeahead" className="typeahead">
               <form data-search="">
                  <label id="typeahead-0.xu47853jhj-label" htmlFor="typeahead-0.xu47853jhj"
                         className="svelte-wqugyy">Search</label>
                  <input name="search"
                         type="search"
                         placeholder="Αναζήτηση…"
                         autoComplete="off"
                         spellCheck="false"
                         id="typeahead-0.xu47853jhj"
                         aria-autocomplete="list"
                         aria-controls="typeahead-0.xu47853jhj-listbox"
                         aria-labelledby="typeahead-0.xu47853jhj-label"
                         className="Search"></input>
               </form>
               <ul role="listbox" aria-labelledby="typeahead-0.xu47853jhj-label"
                   id="typeahead-0.xu47853jhj-listbox" className="typeahead typeahead-list">
               </ul>
            </div>
            <div className="pointer-events-none absolute right-8 top-2 gap-1 opacity-50 hidden lg:flex"><kbd
               className="kbd kbd-sm">⌘</kbd> <kbd className="kbd kbd-sm">K</kbd></div>
         </label>
      </div>
   );
};

export default NavBarSearch;