// src/components/Translations/Translations.js

import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import IconHome from "../../components/Icons/IconHome";
import TranslationsCategories from "./TranslationsCategories";
import TranslationsProducts from "./TranslationsProducts";
import TranslationsSettings from "./TranslationsSettings";

const Translations = () => {
   const title = 'Μεταφράσεις'

   const [activeTab, setActiveTab] = useState(0);

   const tabs = [
      {label: 'Κατηγορίες', content: <TranslationsCategories/>},
      {label: 'Προϊόντα', content: <TranslationsProducts/>},
      {label: 'Ρυθμίσεις', content: <TranslationsSettings/>}
   ];

   return (
      <div className="px-6 pb-16">
         <div className="max-w-full text-sm font-medium breadcrumbs py-6">
            <ul>
               <li>
                  <Link to="/dashboard" className="hover:text-primary">
                     <IconHome/>
                     Αρχική
                  </Link>
               </li>
               <li>
                  {title}
               </li>
            </ul>
         </div>
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-4xl flex-grow">
               <h1>{title}</h1>
               <div className="">
                  <div className="tabs font-bold">
                     {tabs.map((tab, index) => (
                        <div
                           key={index}
                           className={`tab tab-lifted ${index === activeTab ? 'tab-active' : ''}`}
                           onClick={() => setActiveTab(index)}
                        >
                           {tab.label}
                        </div>
                     ))}
                  </div>
                  <div className="tab-content">{tabs[activeTab].content}</div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Translations;