// src/components/NavBarThemeComponent.js

import React, {useState, useEffect} from 'react';


const NavBarThemeComponent = (props) => {

   return (
      <div className="outline-base-content overflow-hidden rounded-lg outline-2 outline-offset-2"
           data-set_theme={props.theme} data-act-class="outline" onClick={props.onClick}>
         <div data-theme={props.theme}
              className="bg-base-100 text-base-content w-full cursor-pointer font-sans">
            <div className="grid grid-cols-5 grid-rows-3">
               <div className="col-span-5 row-span-3 row-start-1 flex gap-1 py-3 px-4">
                  <div className="flex-grow text-sm font-bold">{props.theme}</div>
                  <div className="flex flex-shrink-0 flex-wrap gap-1">
                     <div className="bg-primary w-2 rounded"></div>
                     <div className="bg-secondary w-2 rounded"></div>
                     <div className="bg-accent w-2 rounded"></div>
                     <div className="bg-neutral w-2 rounded"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default NavBarThemeComponent;