// src/components/NavBarMenu.js

import React, {useContext, useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import IconProfile from "../../Icons/IconProfile";
import IconSettings from "../../Icons/IconSettings";
import IconLogout from "../../Icons/IconLogout";
import AuthContext from '../../../store/auth-context';


const NavBarMenu = (props) => {
   const authCtx = useContext(AuthContext);

   const logoutHandler = () => {
      authCtx.logout();
      // optional: redirect the user
   };

   return (
      <div className="dropdown dropdown-end">
         <label tabIndex="0" className="btn btn-ghost btn-circle">
            <div className="w-10 h-10 rounded-full flex justify-center items-center bg-base-200">
               {props.splitName}
            </div>
         </label>
         <div className="menu menu-compact dropdown-content bg-base-200 text-base-content rounded-t-box rounded-b-box shadow-2xl w-40 mt-3">
            <div className="py-3 px-5 border-b-2 border-base-300">
               <span className="block text-sm font-bold text-content">{props.name}</span>
               <span
                  className="block text-sm font-sm text-content opacity-60 truncate">{props.email}</span>
            </div>
            <ul tabIndex="0">
               <li>
                  <Link to={'/profile'}>
                     <IconProfile/>
                     Προφίλ
                  </Link>
               </li>
               <li>
                  <Link to={'/settings'}>
                     <IconSettings/>
                     Ρυθμίσεις
                  </Link>
               </li>
               <li>
                  <button onClick={logoutHandler}>
                     <IconLogout/>
                     Αποσύνδεση
                  </button>
               </li>
            </ul>
         </div>
      </div>
   );
};

export default NavBarMenu;