import React, {useState, useEffect} from "react";
import Item from './MenuProductsItem';
import List from './MenuProductsList';


const MenuProducts = (props) => {

   const [items, setItems] = useState([]);

   useEffect(() => setItems(props.items), [props]);

   return (
      <div className="w-full max-w-4xl flex-grow">
         <List
            flush="true"
            id={props.category_id}
            items={items}
            categoryId={props.category_id}
            onReorder={(newItems) => setItems(newItems)}
            setIsDrawerOpen={props.setIsDrawerOpen}
            setIsCategory={props.setIsCategory}
            setIsProduct={props.setIsProduct}
            setCategoryId={props.setCategoryId}
            setProductId={props.setProductId}
         >
            {items.map((item) => (
               <Item
                  id={item.id}
                  key={item.id}
                  item ={item}
                  setIsDrawerOpen={props.setIsDrawerOpen}
                  setIsCategory={props.setIsCategory}
                  setIsProduct={props.setIsProduct}
                  setCategoryId={props.setCategoryId}
                  setProductId={props.setProductId}
                  selected={item.is_active}
                  onSelect={(selected) => {
                     setItems(
                        items.map((i) => {
                           if (i === item) return {...i, selected};
                           return i;
                        })
                     );
                  }}
               >
                  {item.name}
               </Item>
            ))}
         </List>
      </div>
   );
};

export default MenuProducts;
