// src/components/NavBar.js

import React, {useRef, useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import NavBarSearch from "./NavBarSearch";
import NavBarMenu from "./NavBarMenu";
import NavBarThemeMenu from "./NavBarThemeMenu";
import IconHamburger from "../../Icons/IconHamburger";
import config from "../../../config.json";


const NavBar = (props) => {

   return (
      <div
         className='sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content'>
         <nav className="navbar w-full">
            <div className="flex flex-1 md:gap-1 lg:gap-2">
               <label htmlFor="drawer" className="btn btn-square btn-ghost drawer-button lg:hidden">
                  <IconHamburger/>
               </label>
               <div className="flex items-center gap-2 lg:hidden">
                  <Link to="/dashboard" className="flex-0 btn btn-ghost px-2 ">
                     <div className="flex flex-col items-end space-x-1">
                    <img className="w-[150px]" src="/menunow.png" alt=""/>
                  </div>
                  </Link>
                  {/*<div className="font-mono text-xs text-opacity-50">{config.VERSION}</div>*/}
               </div>
               {/*<NavBarSearch/>*/}
            </div>
            <div className="flex-0">
               {/*<NavBarThemeMenu/>*/}
               <div className="font-medium pr-2">{props.name}</div>
               <NavBarMenu
                  name={props.name}
                  email={props.email}
                  splitName={props.splitName}
               />
            </div>
         </nav>
      </div>
   );
};

export default NavBar;