import React from 'react';

const IconChevronDown = (props) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
           className={props.addClass}>
         <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
   );
}
export default IconChevronDown;