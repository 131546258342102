// src/components/ChangeTheme.js

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import ThemeComponent from "./ThemeComponent";
import IconHome from "../../components/Icons/IconHome";
import config from "../../config.json";

const Theme = () => {
   const title = 'Αλλαγή Θέματος'

   const setTheme = (e) => {
      const theme = e.currentTarget.dataset['set_theme']
      const div = document.getElementById('root').querySelector("div");
      div.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
   }, [title, config])

   return (
      <div className="px-6 pb-16">
         <div className="max-w-full text-sm font-medium breadcrumbs py-6">
            <ul>
               <li>
                  <Link to="/dashboard" className="hover:text-primary">
                     <IconHome/>
                     Αρχική
                  </Link>
               </li>
               <li>
                  {title}
               </li>
            </ul>
         </div>
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-4xl flex-grow">
               <h1>{title}</h1>
               <p>Επιλέξτε το θέμα του διαχειριστικού περιβάλλοντος που σας αρέσει.</p>
               <div className="rounded-box grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                  <ThemeComponent theme={'default'} onClick={setTheme}/>
                  <ThemeComponent theme={'light'} onClick={setTheme}/>
                  <ThemeComponent theme={'dark'} onClick={setTheme}/>
                  <ThemeComponent theme={'cupcake'} onClick={setTheme}/>
                  <ThemeComponent theme={'bumblebee'} onClick={setTheme}/>
                  <ThemeComponent theme={'emerald'} onClick={setTheme}/>
                  <ThemeComponent theme={'corporate'} onClick={setTheme}/>
                  <ThemeComponent theme={'synthwave'} onClick={setTheme}/>
                  <ThemeComponent theme={'retro'} onClick={setTheme}/>
                  <ThemeComponent theme={'cyberpunk'} onClick={setTheme}/>
                  <ThemeComponent theme={'valentine'} onClick={setTheme}/>
                  <ThemeComponent theme={'halloween'} onClick={setTheme}/>
                  <ThemeComponent theme={'garden'} onClick={setTheme}/>
                  <ThemeComponent theme={'forest'} onClick={setTheme}/>
                  <ThemeComponent theme={'aqua'} onClick={setTheme}/>
                  <ThemeComponent theme={'lofi'} onClick={setTheme}/>
                  <ThemeComponent theme={'pastel'} onClick={setTheme}/>
                  <ThemeComponent theme={'fantasy'} onClick={setTheme}/>
                  <ThemeComponent theme={'wireframe'} onClick={setTheme}/>
                  <ThemeComponent theme={'black'} onClick={setTheme}/>
                  <ThemeComponent theme={'luxury'} onClick={setTheme}/>
                  <ThemeComponent theme={'dracula'} onClick={setTheme}/>
                  <ThemeComponent theme={'cmyk'} onClick={setTheme}/>
                  <ThemeComponent theme={'autumn'} onClick={setTheme}/>
                  <ThemeComponent theme={'business'} onClick={setTheme}/>
                  <ThemeComponent theme={'acid'} onClick={setTheme}/>
                  <ThemeComponent theme={'lemonade'} onClick={setTheme}/>
                  <ThemeComponent theme={'night'} onClick={setTheme}/>
                  <ThemeComponent theme={'coffee'} onClick={setTheme}/>
                  <ThemeComponent theme={'winter'} onClick={setTheme}/>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Theme;