import React, {useContext, useEffect, useRef, useState} from 'react';
import {DndContext, closestCenter} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import IconPlus from "../../components/Icons/IconPlus";
import config from "../../config.json";

const List = ({
                 children,
                 id,
                 categoryId,
                 items: defaultItems,
                 onReorder,
                 setIsDrawerOpen,
                 setIsCategory,
                 setIsProduct,
                 setCategoryId,
                 setProductId,
                 ...props
              }) => {

   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)

   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/products/reorder`

   const [items, setItems] = useState(defaultItems);
   // const [catId, setCatId] = useState(id);
   const [sort, setSort] = useState([]);

   const notInitialRender = useRef(false);

   const handleDragEnd = ({active, over}) => {
      if (!over) {
         return;
      }
      console.log(active.id);
      console.log(over.id);
      if (active.id !== over.id) {
         setItems((items) => {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            let newItems = arrayMove(items, oldIndex, newIndex);
            console.log(newItems);
            postSortProducts(newItems);
            return newItems
         });
      }
   };

   const postSortProducts = async (newItems) => {
      console.log('--- Sort ---')
      setSort([]);
      for (let i = 0; i < newItems.length; i++) {
         sort.push({sort: i, id: newItems[i]['id']});
      }
      console.log(sort);
      console.log('--- end Sort ---')
      let data = {"data": sort}
      axios.post(url, data, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
         .then(function (response) {
            console.log(response);
         })
         .catch(function (error) {
            console.log(error);
         });
   }

   useEffect(() => setItems(defaultItems), [defaultItems]);
   // useEffect(() => setCatId(id), [id]);

   useEffect(() => {
      if (notInitialRender.current && onReorder) onReorder(items);
      else notInitialRender.current = true;
   }, [items]);

   return (
      <DndContext modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
         <div className="ml-10">
            <div className="card bg-base-100 shadow-xl border-solid border-2 border-base-200 my-4">
               <button
                  className="flex items-center cursor-pointer px-4 py-4"
                  onClick={() => {
                     setIsDrawerOpen(true);
                     setIsProduct(true);
                     setIsCategory(false)
                     // setCategoryId(id)
                     setProductId("")
                     console.log('id: ' + id)
                     console.log('categoryId: ' + categoryId)
                  }}
               >
                  <IconPlus addClass="w-4 h-4 fill-current opacity-80 mr-1"/>Προσθήκη Προϊόντος
               </button>
            </div>
            <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
               <ul className="flex flex-col space-y-2" {...props}>{children}</ul>
            </SortableContext>
         </div>
      </DndContext>
   );
};

export default List;
