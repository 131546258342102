// src/components/Settings.js

import React, {useState, useEffect, useContext} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthContext from "../store/auth-context";
import IconHome from "../components/Icons/IconHome";
import {successToast, errorToast} from "../components/Layout/Toast"
import config from "../config.json";

const Settings = () => {

   const authCtx = useContext(AuthContext)

   const [logo, setLogo] = useState(null);
   const [headerImage, setHeaderImage] = useState(null);

   const [headerBgColor, setHeaderBgColor] = useState('');
   const [headerTextColor, setHeaderTextColor] = useState('');

   const [categoryBgColor, setCategoryBgColor] = useState('');
   const [categoryTextColor, setCategoryTextColor] = useState('');

   const [productBgColor, setProductBgColor] = useState('');
   const [productTextColor, setProductTextColor] = useState('');

   const [notesBgColor, setNotesBgColor] = useState('');
   const [notesTextColor, setNotesTextColor] = useState('');

   const [footerBgColor, setFooterBgColor] = useState('');
   const [footerTextColor, setFooterTextColor] = useState('');

   const [facebook, setFacebook] = useState('');
   const [instagram, setInstagram] = useState('');
   const [tikTok, setTikTok] = useState('');
   const [website, setWebsite] = useState('');
   const [telephone, setTelephone] = useState('');
   const [footerNotes, setFooterNotes] = useState('');
   const [headerScripts, setHeaderScripts] = useState('');
   const [customCSS, setCustomCSS] = useState('');
   const [errors, setErrors] = useState({
      headerBgColor: "",
      headerTextColor: "",
      categoryBgColor: "",
      categoryTextColor: "",
      productBgColor: "",
      productTextColor: "",
      notesBgColor: "",
      notesTextColor: "",
      footerBgColor: "",
      footerTextColor: ""
   })

   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/settings/`

   const title = 'Ρυθμίσεις'

   const logoChangeHandler = (event) => {
      postImage(event, "logo");
   };

   const logoClickHandler = (event) => {
      deleteImage(event, "logo");
   };

   const headerImageChangeHandler = (event) => {
      postImage(event, "background");
   };

   const headerImageClickHandler = (event) => {
      deleteImage(event, "background");
   };

   const headerBgColorChangeHandler = (event) => {
      // console.log('headerBgColor: ' + event.target.value)
      setHeaderBgColor(event.target.value);
   };
   const headerTextColorChangeHandler = (event) => {
      setHeaderTextColor(event.target.value)
   };
   const categoryBgColorChangeHandler = (event) => {
      // console.log('headerBgColor: ' + color.hex);
      setCategoryBgColor(event.target.value)
   };
   const categoryTextColorChangeHandler = (event) => {
      setCategoryTextColor(event.target.value)
   };
   const productBgColorChangeHandler = (event) => {
      setProductBgColor(event.target.value)
   };
   const productTextColorChangeHandler = (event) => {
      setProductTextColor(event.target.value)
   };

   const notesBgColorChangeHandler = (event) => {
      setNotesBgColor(event.target.value)
   };
   const notesTextColorChangeHandler = (event) => {
      setNotesTextColor(event.target.value)
   };
   const footerBgColorChangeHandler = (event) => {
      setFooterBgColor(event.target.value)
   };
   const footerTextColorChangeHandler = (event) => {
      setFooterTextColor(event.target.value)
   };
   const facebookChangeHandler = (event) => {
      setFacebook(event.target.value);
   };
   const instagramChangeHandler = (event) => {
      setInstagram(event.target.value);
   };
   const tikTokChangeHandler = (event) => {
      setTikTok(event.target.value);
   };
   const websiteChangeHandler = (event) => {
      setWebsite(event.target.value);
   };
   const telephoneChangeHandler = (event) => {
      setTelephone(event.target.value);
   };
   const footerNotesChangeHandler = (event) => {
      setFooterNotes(event.target.value);
   };
   const headerScriptsChangeHandler = (event) => {
      setHeaderScripts(event.target.value);
   };
   const customCSSChangeHandler = (event) => {
      setCustomCSS(event.target.value);
   };

   const submitHandler = (event) => {
      event.preventDefault();
      let formIsValid = true;
      setErrors({
         headerBgColor: "",
         headerTextColor: "",
         categoryBgColor: "",
         categoryTextColor: "",
         productBgColor: "",
         productTextColor: "",
         notesBgColor: "",
         notesTextColor: "",
         footerBgColor: "",
         footerTextColor: ""
      });
      if (!headerBgColor) {
         formIsValid = false;
         setErrors({headerBgColor: "Cannot be empty"});
      }
      if (!headerTextColor) {
         formIsValid = false;
         setErrors({headerTextColor: "Cannot be empty"});
      }
      if (!categoryBgColor) {
         formIsValid = false;
         setErrors({categoryBgColor: "Cannot be empty"});
      }
      if (!categoryTextColor) {
         formIsValid = false;
         setErrors({categoryTextColor: "Cannot be empty"});
      }
      if (!productBgColor) {
         formIsValid = false;
         setErrors({productBgColor: "Cannot be empty"});
      }
      if (!productTextColor) {
         formIsValid = false;
         setErrors({productTextColor: "Cannot be empty"});
      }
      if (!notesBgColor) {
         formIsValid = false;
         setErrors({notesBgColor: "Cannot be empty"});
      }
      if (!notesTextColor) {
         formIsValid = false;
         setErrors({notesTextColor: "Cannot be empty"});
      }
      if (!footerBgColor) {
         formIsValid = false;
         setErrors({footerBgColor: "Cannot be empty"});
      }
      if (!footerTextColor) {
         formIsValid = false;
         setErrors({footerTextColor: "Cannot be empty"});
      }
      if (formIsValid) {
         const eData = {
            logo: logo,
            header_image: headerImage,
            header_bg_color: headerBgColor,
            header_text_color: headerTextColor,
            category_bg_color: categoryBgColor,
            category_text_color: categoryTextColor,
            product_bg_color: productBgColor,
            product_text_color: productTextColor,
            notes_bg_color: notesBgColor,
            notes_text_color: notesTextColor,
            footer_bg_color: footerBgColor,
            footer_text_color: footerTextColor,
            facebook: facebook,
            instagram: instagram,
            tiktok: tikTok,
            website: website,
            telephone: telephone,
            footer_notes: footerNotes,
            header_scripts: headerScripts,
            custom_css: customCSS,
         }
         // console.log(eData)
         axios
            .put(url, eData, {
               withCredentials: true,
               headers: {
                  Authorization: `Bearer ${authCtx.token}`
               }
            })
            .then(function (response) {
               successToast('Οι αλλαγές αποθηκεύτηκαν!')
            })
            .catch(function (error) {
               console.log(error);
               errorToast('Οι αλλαγές δεν αποθηκεύτηκαν')
            });
      }
   }

   const fetchSettings = async () => {
      axios
         .get(url, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
         .then(response => {
            // console.log(response.data);
            setLogo(response.data.logo);
            setHeaderImage(response.data.header_image);
            setHeaderBgColor(response.data.header_bg_color);
            setHeaderTextColor(response.data.header_text_color);
            setCategoryBgColor(response.data.category_bg_color);
            setCategoryTextColor(response.data.category_text_color);
            setProductBgColor(response.data.product_bg_color);
            setProductTextColor(response.data.product_text_color);
            setNotesBgColor(response.data.notes_bg_color);
            setNotesTextColor(response.data.notes_text_color);
            setFooterBgColor(response.data.footer_bg_color);
            setFooterTextColor(response.data.footer_text_color);
            setFacebook(response.data.facebook);
            setInstagram(response.data.instagram);
            setTikTok(response.data.tiktok);
            setWebsite(response.data.website);
            setTelephone(response.data.telephone);
            setFooterNotes(response.data.footer_notes);
            setHeaderScripts(response.data.header_scripts);
            setCustomCSS(response.data.custom_css);
         });
   }

   const postImage = async (event, image) => {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
         .post((url + 'upload/' + image), formData, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
         .then(response => {
            // console.log(response.data);
            if (image === "logo") {
               setLogo(response.data.url);
            } else {
               setHeaderImage(response.data.url);
            }
            successToast('Η φωτογραφία αποθηκεύτηκε!')
         })
         .catch(function (error) {
            errorToast('Η φωτογραφία δεν αποθηκεύτηκε!')
         });
   }

   const deleteImage = async (event, image) => {
      axios
         .delete((url + 'upload/' + image), {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
         .then(response => {
            // console.log(response.data);
            if (image === "logo") {
               setLogo(null);
            } else if (image === "background") {
               setHeaderImage(null);
            }
            successToast('Η φωτογραφία αφαιρέθηκε!')
         })
         .catch(function (error) {
            errorToast('Η φωτογραφία δεν αφαιρέθηκε!')
         });
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchSettings();
   }, [title, config])

   return (
      <div className="px-6 pb-16">
         <div className="max-w-full text-sm font-medium breadcrumbs py-6">
            <ul>
               <li>
                  <Link to="/dashboard" className="hover:text-primary">
                     <IconHome/>
                     Αρχική
                  </Link>
               </li>
               <li>
                  {title}
               </li>
            </ul>
         </div>
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-2xl flex-grow">

               <h1>{title}</h1>

               <div className="px-4 py-5 sm:p-6 space-y-6">

                  <div className="flex lg:flex-row flex-col items-center">
                     <div className="label-text lg:w-1/4 w-full">Logo</div>
                     <div className="lg:w-3/4 w-full">
                        <div
                           className="flex justify-center border-2 border-primary border-dashed rounded-md pt-6 p-4">
                           {logo && (
                              <div>
                                 {/*<img className="m-0" width={"200px"} src={URL.createObjectURL(logo)} />*/}
                                 <img className="m-0" width={"200px"} src={logo}/>
                                 <button className="text-error text-sm" onClick={logoClickHandler}>Αφαίρεση</button>
                              </div>
                           )}
                           {!logo && (
                              <label htmlFor="logo-upload">
                                 <div className="space-y-1 text-center">
                                    <svg className="mx-auto h-12 w-12 text-primary" stroke="currentColor" fill="none"
                                         viewBox="0 0 48 48" aria-hidden="True">
                                       <path
                                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className="flex text-sm text-primary">
                                       <div
                                          className="relative cursor-pointer rounded-md font-medium text-accent hover:text-accent-focus focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                          {/*<span>Upload a file</span>*/}
                                          <span>Προσθέστε μια εικόνα</span>
                                          <input
                                             type="file" className="sr-only" id="logo-upload"
                                             onChange={logoChangeHandler}
                                          />
                                       </div>
                                       {/*<p className="m-0 pl-1">ή σύρετε εδώ αρχεία για μεταφόρτωση</p>*/}
                                    </div>
                                    <p className="text-xs text-gray-500">
                                       PNG, JPG, JPEG μέχρι 1MB
                                    </p>
                                 </div>
                              </label>
                           )}
                        </div>
                     </div>
                  </div>

                  <div className="flex lg:flex-row flex-col items-center">
                     <div className="label-text lg:w-1/4 w-full">Background</div>
                     <div className="lg:w-3/4 w-full">
                        <div
                           className="flex justify-center border-2 border-primary border-dashed rounded-md pt-6 p-4">
                           {headerImage && (
                              <div>
                                 {/*<img className="m-0" width={"200px"} src={URL.createObjectURL(headerImage)} />*/}
                                 <img className="m-0" width={"200px"} src={headerImage}/>
                                 <button className="text-error text-sm" onClick={headerImageClickHandler}>Αφαίρεση
                                 </button>
                              </div>
                           )}
                           {!headerImage && (
                              <label htmlFor="bg-upload">
                                 <div className="space-y-1 text-center">
                                    <svg className="mx-auto h-12 w-12 text-primary" stroke="currentColor" fill="none"
                                         viewBox="0 0 48 48" aria-hidden="True">
                                       <path
                                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className="flex text-sm text-primary">
                                       <div
                                          className="relative cursor-pointer rounded-md font-medium text-accent hover:text-accent-focus focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                          {/*<span>Upload a file</span>*/}
                                          <span>Προσθέστε μια εικόνα</span>
                                          <input
                                             type="file" className="sr-only" id="bg-upload"
                                             onChange={headerImageChangeHandler}
                                          />
                                       </div>
                                       {/*<p className="m-0 pl-1">ή σύρετε εδώ αρχεία για μεταφόρτωση</p>*/}
                                    </div>
                                    <p className="text-xs text-gray-500">
                                       PNG, JPG, JPEG μέχρι 1MB
                                    </p>
                                 </div>
                              </label>
                           )}
                        </div>
                     </div>
                  </div>

                  <form onSubmit={submitHandler}>
                     <div className="space-y-6">

                        <div className="flex items-center">
                           <div className="label-text w-3/4">Header Background Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={headerBgColorChangeHandler}
                                     value={headerBgColor == null ? '' : headerBgColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Header Text Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={headerTextColorChangeHandler}
                                     value={headerTextColor == null ? '' : headerTextColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Category Background Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={categoryBgColorChangeHandler}
                                     value={categoryBgColor == null ? '' : categoryBgColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Category Text Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={categoryTextColorChangeHandler}
                                     value={categoryTextColor == null ? '' : categoryTextColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Product Background Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={productBgColorChangeHandler}
                                     value={productBgColor == null ? '' : productBgColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Product Text Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={productTextColorChangeHandler}
                                     value={productTextColor == null ? '' : productTextColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Notes Background Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={notesBgColorChangeHandler}
                                     value={notesBgColor == null ? '' : notesBgColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Notes Text Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={notesTextColorChangeHandler}
                                     value={notesTextColor == null ? '' : notesTextColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Footer Background Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={footerBgColorChangeHandler}
                                     value={footerBgColor == null ? '' : footerBgColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex items-center">
                           <div className="label-text w-3/4">Footer Text Color</div>
                           <div className="w-1/4">
                              <input type="color" onChange={footerTextColorChangeHandler}
                                     value={footerTextColor == null ? '' : footerTextColor}
                                     className="input input-color input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Facebook</div>
                           <div className="lg:w-3/4 w-full">
                              <input type="text" onChange={facebookChangeHandler} value={(facebook ? facebook : '')}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Instagram</div>
                           <div className="lg:w-3/4 w-full">
                              <input type="text" onChange={instagramChangeHandler}
                                     value={instagram ? instagram : ''}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Tik Tok</div>
                           <div className="lg:w-3/4 w-full">
                              <input type="text" onChange={tikTokChangeHandler} value={tikTok ? tikTok : ''}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Website</div>
                           <div className="lg:w-3/4 w-full">
                              <input type="text" onChange={websiteChangeHandler} value={website == null ? '' : website}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Telephone</div>
                           <div className="lg:w-3/4 w-full">
                              <input type="text" onChange={telephoneChangeHandler}
                                     value={telephone == null ? '' : telephone}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Footer Notes</div>
                           <div className="lg:w-3/4 w-full">
                              {/*<input type="text" onChange={footerNotesChangeHandler} value={footerNotes == null ? '' :*/}
                              {/*   footerNotes} placeholder="" className="input input-bordered input-primary w-full"/>*/}
                              <textarea className="textarea textarea-primary w-full max-w-4xl" rows="3"
                                        onChange={footerNotesChangeHandler}
                                        value={footerNotes == null ? '' : footerNotes}></textarea>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Header Scripts</div>
                           <div className="lg:w-3/4 w-full">
                        <textarea className="textarea textarea-primary w-full max-w-4xl" rows="5"
                                  onChange={headerScriptsChangeHandler}
                                  value={((headerScripts) ? headerScripts : '')}></textarea>
                           </div>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-y-1 lg:space-y-0">
                           <div className="label-text lg:w-1/4 w-full">Custom CSS</div>
                           <div className="lg:w-3/4 w-full">
                        <textarea className="textarea textarea-primary w-full max-w-4xl" rows="5"
                                  onChange={customCSSChangeHandler}
                                  value={((customCSS) ? customCSS : '')}></textarea>
                           </div>
                        </div>

                        <div className="py-3 bg-base-200 bg-opacity-20 text-right sm:px-6 max-w-2xl">
                           <button className="btn btn-primary">ΑΠΟΘΗΚΕΥΣΗ</button>
                        </div>

                     </div>

                  </form>

               </div>
            </div>
         </div>
      </div>
   );
};

export default Settings;