// src/components/Translations/TranslationsCategories.js

import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import jwtDecode from "jwt-decode";
import config from "../../config.json";
import classnames from 'classnames';

const Translations = () => {
   const authCtx = useContext(AuthContext)

   const decoded = jwtDecode(authCtx.token)
   const category_url = `${config.API_URL}/tenants/${decoded.tenant_id}/categories/`

   const title = 'Μεταφράσεις'

   const [categories, setCategories] = useState([]);
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [selectedCategoryId, setSelectedCategoryId] = useState(null);
   const [category, setCategory] = useState({name: '', description: ''});
   const [formValues, setFormValues] = useState({name: '', description: '', language_code: ''});

   const fetchCategories = async () => {
      const {data} = await axios.get(category_url, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      const categories = data;
      setCategories(categories);
      console.log(categories);
   };

   const fetchCategory = async (id) => {
      await axios.get(category_url + id, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      }).then((response) => {
         console.log(response.data);
         const category = response.data;
         setCategory({name: category.name, description: category.description});
         console.log(category);
      })
   };

   const fetchTranslatedCategory = (id) => {
      axios.get(category_url + id + "/translations", {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      }).then((response) => {
         console.log(response.data);
         const category = response.data;
         setFormValues({name: category.name, description: category.description, language_code: category.language_code});
         console.log(category);
      }).catch(error => {
         setFormValues({name: "", description: "", language_code: "en"});
         // console.log(error.message); // logs "Request failed with status code 404"
      })
   };

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchCategories();
   }, [title, config])

   const handleCategoryClick = (category) => {
      setSelectedCategoryId(category.id);
      setSelectedCategory(category);
      fetchCategory(category.id);
      fetchTranslatedCategory(category.id);
      setFormValues({name: '', description: ''});
   };

   const handleFormInputChange = (event) => {
      const {name, value} = event.target;
      console.log(`Name: ${name}, Value: ${value}`);
      setFormValues((prevValues) => ({...prevValues, [name]: value}));
   };

   const handleFormSubmit = (event) => {
      event.preventDefault();
      const {name, description} = formValues;
      const payload = {name, description, language_code: 'en'};
      axios.post(category_url + `${selectedCategory.id}/translations`,
         payload, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         }
      ).then((response) => {
         console.log(response.data);
      });
   };

   return (
      <>
         <div className="lg:grid grid-cols-4 pt-12 gap-12">
            <div className="col-span-1 hidden lg:block">
               <div className="bg-primary rounded-md flex justify-center items-center">
                  <div className="py-2 text-base-100 text-sm">Κατηγορίες</div>
               </div>
               <ul className="translation-list">
                  {categories.map((category) => (
                     <li key={category.id}
                         className={classnames({selected: category.id === selectedCategoryId})}
                         onClick={() => handleCategoryClick(category)}>
                        {category.name}
                     </li>
                  ))}
               </ul>
            </div>
            {selectedCategory && (
               <div className="lg:col-span-3">
                  <div className="overflow-x-scroll overscroll-contain">
                     <form onSubmit={handleFormSubmit}>
                        <div className="overflow-x-auto">
                           <table className="overflow-x-scroll overscroll-contain mt-0">
                              <thead>
                              <tr>
                                 <th
                                    className="border border-separate p-4 min-w-[200px] font-bold bg-primary text-white rounded-tl-lg border-t-0 border-l-0 border-b-0">Ελληνικά
                                 </th>
                                 <th
                                    className="border border-separate p-4 min-w-[200px] font-bold bg-primary text-white border-b-0">English
                                 </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                 <td className="border-separate border-l-2 p-4 min-w-[200px]">
                                    <div className="text-xs opacity-60 pb-2">Όνομα</div>
                                    <div>{category.name}</div>
                                 </td>
                                 <td className="border border-separate border-t-0 p-4 min-w-[200px]">
                                    <div><label className="text-xs" htmlFor="name">Name</label></div>
                                    <input value={formValues.name} onChange={handleFormInputChange}
                                           className="bg-base-100 text-primary placeholder:text-secondary w-full pt-1"
                                           placeholder="Name"
                                           type="text" name="name"/>
                                 </td>
                              </tr>
                              <tr>
                                 <td className="border border-separate border-l-2 p-4 min-w-[200px] max-w-[200px]">
                                    <div className="text-xs opacity-60 pb-2">Περιγραφή</div>
                                    <div className="max-h-24 overflow-auto">{category.description}</div>
                                 </td>
                                 <td className="border border-separate p-4 min-w-[200px]">
                                    <div><label className="text-xs" htmlFor="description">Description</label></div>
                                    <textarea value={formValues.description} onChange={handleFormInputChange}
                                              className="bg-base-100 text-primary placeholder:text-secondary w-full h-24 pt-1"
                                              placeholder="Description" name="description"></textarea>
                                 </td>
                              </tr>
                              </tbody>
                           </table>
                        </div>
                        <div className="flex flex-row-reverse">
                           <button className="btn btn-primary" type="submit">ΑΠΟΘΗΚΕΥΣΗ</button>
                        </div>
                     </form>
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default Translations;