// src/components/Feedback.js

import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import FeedbackForm from "./FeedbackForm";
import IconHome from "../../components/Icons/IconHome";
import config from "../../config.json";


const Feedback = () => {
   const [id, setId] = useState();

   const title = 'Αξιολόγηση'

   const params = useParams();

   const fetchFeedback = async () => {
      if (params) {
         console.log(params);
         if (params['feedbackId']) {
            setId(params['feedbackId']);
         }
      }
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchFeedback();
   }, [title, config])

   return (
      <div className="px-6 pb-16">
         <div className="max-w-full text-sm font-medium breadcrumbs py-6">
            <ul>
               <li>
                  <Link to="/dashboard" className="hover:text-primary">
                     <IconHome/>
                     Αρχική
                  </Link>
               </li>
               <li>
                  <Link to="/feedbacks" className="hover:text-primary">Αξιολογήσεις</Link>
               </li>
               <li>
                  {title}
               </li>
            </ul>
         </div>
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-4xl flex-grow">
               <h1>{title}</h1>
               <FeedbackForm id={id}/>
            </div>
         </div>
      </div>
   );
};

export default Feedback;