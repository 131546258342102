import React, {useEffect} from 'react'

import AuthForm from '../components/Auth/AuthForm';
import config from "../config.json";


const Login = () => {
   const title = 'Σύνδεση'

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
   }, [title, config])

   return <AuthForm/>;
}

export default Login