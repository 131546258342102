// src/components/CategoriesTableRow.js

import React from 'react';
import {Link} from "react-router-dom";

const FeedbacksTableRow = (props) => {

   const date = new Date(props.date);

   return (
      <tr className="hover">
         <td>{props.id}</td>
         <td>
            {(() => {
               if (props.name && props.email) {
                  return (
                     props.name + " - " + props.email
                  )
               } else if (props.name) {
                  return (
                     props.name
                  )
               } else if (props.email) {
                  return (
                     props.email
                  )
               } else {
                  return (
                     "Ανώνυμος Πελάτης"
                  )
               }
            })()}
         </td>
         <td>{date.toLocaleString("el-GR")}</td>
         <td>
            <Link className="link link-hover hover:text-primary" to={`/feedbacks/${props.id}`}>Προβολή</Link>
         </td>
      </tr>
   );
};

export default FeedbacksTableRow;