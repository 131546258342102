import React, {useEffect, useState} from 'react'

import AuthResetPassForm from '../components/Auth/AuthResetPassForm';
import config from "../config.json";
import {useParams} from "react-router-dom";


const ResetPass = () => {
   const [tokenId, setTokenId] = useState();

   const params = useParams();

   const title = 'Αλλαγή κωδικού'

   const fetchParams = async () => {
      if (params) {
         if (params['tokenId']) {
            setTokenId(params['tokenId']);
         }
      }
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchParams();
   }, [title, config])

   return <AuthResetPassForm token={tokenId}/>;
}

export default ResetPass