// src/components/DrawerProductForm.js

import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import {errorToast, successToast} from "../../components/Layout/Toast";
import config from "../../config.json";

const DrawerProductForm = (props) => {
   const authCtx = useContext(AuthContext)
   const [id, setId] = useState();
   const [checked, setChecked] = useState(false);
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [price, setPrice] = useState('');
   const [image, setImage] = useState('');
   const [errors, setErrors] = useState({name: "", price: ""})

   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/products/`

   const productId = props.id;

   const rx_live = /^[+-]?\d*(?:[.]\d*)?$/;

   const nameChangeHandler = (event) => {
      console.log('name: ' + event.target.value)
      setName(event.target.value);
   };

   const descriptionChangeHandler = (event) => {
      console.log('description: ' + event.target.value)
      setDescription(event.target.value);
   };

   const priceChangeHandler = (event) => {
      console.log('price: ' + event.target.value)
      let value = event.target.value
      if (value) {
         console.log(value)
         if (value.match(rx_live)) {
            setPrice(value);
            setErrors({price: ""});
         }
      }
   };

   const imageChangeHandler = (event) => {
      postImage(event, id);
   };

   const imageClickHandler = (event) => {
      deleteImage(event, id);
   };

   // const formIsValid = false;
   const submitHandler = (event) => {
      event.preventDefault();
      let formIsValid = true;
      setErrors({name: "", price: ""});
      if (!name) {
         formIsValid = false;
         setErrors({name: "Cannot be empty"});
      }
      if (price) {
         if (!String(price).match(rx_live)) {
            formIsValid = false;
            console.log(formIsValid);
            setErrors({price: "Only numbers"});
         }
      }
      if (formIsValid) {
         const eData = {
            is_active: checked,
            name: name,
            description: description,
            price: Number(price).toFixed(2),
            image: image,
            category_id: props.categoryId
         }
         console.log(eData)
         if (id) {
            axios.put(url + id, eData, {
               withCredentials: true,
               headers: {
                  Authorization: `Bearer ${authCtx.token}`
               }
            })
               .then(function (response) {
                  console.log(response);
                  props.setDrawerSubmitted(true)
                  props.setDrawerSubmittedName(name)
                  props.setDrawerSubmittedStatus(checked)
                  props.setDrawerSubmittedImage(image)
                  successToast('Οι αλλαγές αποθηκεύτηκαν!');
               })
               .catch(function (error) {
                  console.log(error);
                  errorToast('Οι αλλαγές δεν αποθηκεύτηκαν');
               });
         } else {
            axios.post(url, eData, {
               withCredentials: true,
               headers: {
                  Authorization: `Bearer ${authCtx.token}`
               }
            })
               .then(function (response) {
                  console.log(response);
                  props.setDrawerSubmitted(true)
                  successToast('Οι αλλαγές αποθηκεύτηκαν!');
               })
               .catch(function (error) {
                  console.log(error);
                  errorToast('Οι αλλαγές δεν αποθηκεύτηκαν');
               });
         }
      }
   }

   const removeItemClickHandler = () => {
      if (id) {
         axios.delete(url + id, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
            .then(function (response) {
               console.log(response);
               props.setDrawerSubmitted(true)
               props.setDrawerSubmittedName(name)
               props.setDrawerSubmittedStatus(checked)
               props.setDrawerSubmittedImage(image)
               successToast('Το προϊόν διαγράφηκε');
            })
            .catch(function (error) {
               console.log(error);
               errorToast('Το προϊόν δεν διαγράφηκε');
            });
      }
   };


   const postImage = async (event, itemId) => {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
         .post((url + 'upload/' + itemId), formData, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
         .then(response => {
            setImage(response.data.url);
            successToast('Η φωτογραφία αποθηκεύτηκε!')
         })
         .catch(function (error) {
            errorToast('Η φωτογραφία δεν αποθηκεύτηκε!')
         });
   }

   const deleteImage = async (event, itemId) => {
      axios
         .delete((url + 'upload/' + itemId), {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         })
         .then(response => {
            setImage(response.data.url);
            successToast('Η φωτογραφία αφαιρέθηκε!')
         })
         .catch(function (error) {
            errorToast('Η φωτογραφία δεν αφαιρέθηκε!')
         });
   }

   useEffect(() => {
      const fetchProduct = async () => {

         setId("");
         setChecked(false);
         setName("");
         setDescription("");
         setPrice("");
         setImage("");

         if (productId) {
            setId(productId);
            axios
               .get(url + productId, {
                  withCredentials: true,
                  headers: {
                     Authorization: `Bearer ${authCtx.token}`
                  }
               })
               .then(response => {
                  console.log(response.data);
                  setChecked(response.data.is_active);
                  setName(response.data.name);
                  setDescription(response.data.description);
                  setPrice(response.data.price);
                  setImage(response.data.image);
               });
         }
      }
      fetchProduct();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [productId, authCtx]);
   // }, []);

   return (
      <form onSubmit={submitHandler}>
         <div className="px-4 py-5 space-y-2 sm:p-6">
            <div className="form-control">
               <label className="label cursor-pointer">
                  <span className="label-text">Ενεργό</span>
                  <input type="checkbox" className="toggle toggle-primary"
                         checked={checked}
                         onChange={(e) => setChecked(e.target.checked)}
                  />
               </label>
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Όνομα</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="text" onChange={nameChangeHandler} value={name}
                         placeholder="" className="input input-bordered input-primary w-full"/>
               </div>
               <span className="label text-error">{errors.name}</span>
               {/*<label className="label">*/}
               {/*   <span className="label-text-alt">Το όνομα που εμφανίζεται στον κατάλογο σας.</span>*/}
               {/*</label>*/}
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Περιγραφή</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <textarea className="textarea textarea-primary w-full max-w-4xl"
                            onChange={descriptionChangeHandler} value={((description) ? description : '')}></textarea>
               </div>
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Τιμή</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="text" onChange={priceChangeHandler} value={price}
                         placeholder="" className="input input-bordered input-primary w-full"/>
               </div>
               <span className="label text-error">{errors.price}</span>
            </div>


            <div>
               <div className="label-text pb-2">Φωτογραφία</div>
               <div className="flex justify-center border-2 border-primary border-dashed rounded-md pt-6 p-4">
                  {image && (
                     <div>
                        {/*<img className="m-0" width={"200px"} src={URL.createObjectURL(logo)} />*/}
                        <img className="m-0" width={"200px"} src={image}/>
                        <span className="text-error text-sm cursor-pointer" onClick={imageClickHandler}>Αφαίρεση</span>
                     </div>
                  )}
                  {!image && (
                     <label htmlFor="logo-upload">
                        <div className="space-y-1 text-center">
                           <svg className="mx-auto h-12 w-12 text-primary" stroke="currentColor" fill="none"
                                viewBox="0 0 48 48" aria-hidden="True">
                              <path
                                 d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                           </svg>
                           <div className="flex text-sm text-primary">
                              <div
                                 className="relative cursor-pointer rounded-md font-medium text-accent hover:text-accent-focus focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                 {/*<span>Upload a file</span>*/}
                                 <span>Προσθέστε μια εικόνα</span>
                                 <input
                                    type="file" className="sr-only" id="logo-upload"
                                    onChange={imageChangeHandler}
                                 />
                              </div>
                              {/*<p className="m-0 pl-1">ή σύρετε εδώ αρχεία για μεταφόρτωση</p>*/}
                           </div>
                           <p className="text-xs text-gray-500">
                              PNG, JPG, JPEG μέχρι 1MB
                           </p>
                        </div>
                     </label>
                  )}
               </div>
            </div>

         </div>
         <div className="px-4 sm:px-6 py-3 flex justify-between">
            <button className="btn btn-primary">{id ? 'ΑΠΟΘΗΚΕΥΣΗ' : 'ΠΡΟΣΘΗΚΗ'}</button>
            {id ?
               <span className="py-3 text-error cursor-pointer" onClick={removeItemClickHandler}>Διαγραφή</span> : ''}
         </div>
      </form>
   );
};

export default DrawerProductForm;