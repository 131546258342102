import React, {useEffect} from 'react'

import AuthForgotPassForm from '../components/Auth/AuthForgotPassForm';
import config from "../config.json";


const ForgotPass = () => {
   const title = 'Επαναφορά κωδικού'

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
   }, [title, config])

   return <AuthForgotPassForm/>;
}

export default ForgotPass