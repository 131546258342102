import React from "react";

import IconClose from "../../components/Icons/IconClose";

export default function Drawer({
                                  children,
                                  isDrawerOpen,
                                  setIsDrawerOpen,
                                  isProduct,
                                  categoryId,
                                  productId
                               }) {
   return (
      <main
         className={
            " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
            (isDrawerOpen
               ? " transition-opacity opacity-100 duration-500 translate-x-0  "
               : " transition-all delay-500 opacity-0 translate-x-full  ")
         }
      >
         <section
            className={
               " w-screen max-w-lg right-0 absolute bg-base-100 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
               (isDrawerOpen ? " translate-x-0 " : " translate-x-full ")
            }
         >
            <div className="relative w-screen max-w-lg pt-16 flex flex-col space-y-6 overflow-y-scroll h-full">
               <div className="flex items-center border-b-2 border-primary">
                  <button
                     className=" cursor-pointer px-4 py-4"
                     onClick={() => {
                        setIsDrawerOpen(false);
                     }}>
                     <IconClose addClass="w-4 h-4 fill-current opacity-80 mr-1"/>
                  </button>
                  <h2>
                     {(isProduct
                           ? (productId
                              ? "Επεξεργασία Προϊόντος"
                              : "Νέο Προϊόν" + categoryId)
                           : (categoryId
                              ? "Επεξεργασία Κατηγορίας" + categoryId
                              : "Νέα Κατηγορία")
                     )}
                  </h2>
               </div>
               {children}
            </div>
         </section>
         <section
            className=" w-screen h-full cursor-pointer "
            onClick={() => {
               setIsDrawerOpen(false);
            }}
         ></section>
      </main>
   );
}