import React from 'react';

const IconClose = (props) => {
   return (
      <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2.5" fill="none"
           strokeLinecap="round" strokeLinejoin="round" className={props.addClass}>
         <line x1="18" y1="6" x2="6" y2="18"></line>
         <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
   );
}
export default IconClose;