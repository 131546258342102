import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import IconHome from "../../components/Icons/IconHome";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import jwtDecode from "jwt-decode";
import Item from './MenuItem';
import List from './MenuList';
import Drawer from "./Drawer";
import DrawerProductForm from "./DrawerProductForm";
import DrawerCategoryForm from "./DrawerCategoryForm";
import config from "../../config.json";


const Menu = () => {
   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/categories/`

   const [items, setItems] = useState([]);
   const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
   const [isCategory, setIsCategory] = React.useState(false);
   const [isProduct, setIsProduct] = React.useState(false);
   const [categoryId, setCategoryId] = React.useState("");
   const [productId, setProductId] = React.useState("");
   const [drawerSubmitted, setDrawerSubmitted] = React.useState(false);
   const [drawerSubmittedName, setDrawerSubmittedName] = React.useState("");
   const [drawerSubmittedImage, setDrawerSubmittedImage] = React.useState("");
   const [drawerSubmittedStatus, setDrawerSubmittedStatus] = React.useState("");
   const [loadProducts, setLoadProducts] = useState(false);

   const title = 'Μενού'

   const fetchCategories = async () => {
      const {data} = await axios.get(url, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      setItems(data);
      console.log('--- items (Menu.js)---');
      console.log(data);
      console.log('--- end items (Menu.js) ---');
   };

   if (drawerSubmitted && isCategory) {
      setDrawerSubmitted(false);
      setIsDrawerOpen(false);
      // console.log('drawerSubmitted && isProduct');
      // console.log(categoryId);
      // console.log(drawerSubmittedName);
      // console.log(drawerSubmittedStatus);
      // if (document.getElementById("category_" + categoryId + "_name")) {
      //    document.getElementById("category_" + categoryId + "_name").innerHTML = drawerSubmittedName;
         // document.getElementById("category_" + categoryId + "_status").checked = drawerSubmittedStatus;
         // document.getElementById("category_" + categoryId + "_img").src = drawerSubmittedImage;
      // } else {
         fetchCategories();
      // }
   }

   if (drawerSubmitted && isProduct) {
      setDrawerSubmitted(false);
      setIsDrawerOpen(false);
      console.log('drawerSubmitted && isProduct');
      console.log('productId: ' + productId);
      console.log('drawerSubmittedName: ' + drawerSubmittedName);
      console.log('drawerSubmittedStatus: ' + drawerSubmittedStatus);
      console.log('categoryId: ' + categoryId);
      // if (document.getElementById("product_" + productId + "_name")) {
      //    document.getElementById("product_" + productId + "_name").innerHTML = drawerSubmittedName;
      //    document.getElementById("product_" + productId + "_status").checked = drawerSubmittedStatus;
      //    // document.getElementById("product_" + productId + "_img").src = drawerSubmittedImage;
      //    console.log(document.getElementById("product_" + productId + "_status").checked);
      // } else {
         setLoadProducts(true)
      // }
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchCategories();
   }, [title, config]);

   return (
      <div>
         <div className="px-6 pb-16">
            <div className="max-w-full text-sm font-medium breadcrumbs py-6">
               <ul>
                  <li>
                     <Link to="/" className="hover:text-primary">
                        <IconHome/>
                        Αρχική
                     </Link>
                  </li>
                  <li>
                     {title}
                  </li>
               </ul>
            </div>

            <div className="prose pb-4"><h1>{title}</h1></div>

            <div className={"w-full max-w-4xl flex-grow"}>
               <List
                  flush="true"
                  id="all"
                  items={items}
                  setIsDrawerOpen={setIsDrawerOpen}
                  setIsCategory={setIsCategory}
                  setIsProduct={setIsProduct}
                  setCategoryId={setCategoryId}
                  setProductId={setProductId}
                  onReorder={(newItems) => setItems(newItems)}
               >
                  {items.map((item) => (
                     <Item
                        id={item.id}
                        key={item.id}
                        item ={item}
                        open={false}
                        loadProducts={loadProducts}
                        setLoadProducts={setLoadProducts}
                        categoryId={categoryId}
                        setIsDrawerOpen={setIsDrawerOpen}
                        setIsCategory={setIsCategory}
                        setIsProduct={setIsProduct}
                        setCategoryId={setCategoryId}
                        setProductId={setProductId}
                        selected={item.is_active}
                        onSelect={(selected) => {
                           setItems(
                              items.map((i) => {
                                 if (i === item) return {...i, selected};
                                 return i;
                              })
                           );
                        }}
                     >
                        {item.name}
                     </Item>
                  ))}
               </List>
            </div>
         </div>

         <Drawer isDrawerOpen={isDrawerOpen}
                 setIsDrawerOpen={setIsDrawerOpen}
                 isCategory={isCategory}
                 isProduct={isProduct}
                 productId={productId}
                 categoryId={categoryId}
         >
            {(isProduct
                  ? <DrawerProductForm id={productId}
                                       categoryId={categoryId}
                                       setDrawerSubmitted={setDrawerSubmitted}
                                       setDrawerSubmittedStatus={setDrawerSubmittedStatus}
                                       setDrawerSubmittedName={setDrawerSubmittedName}
                                       setDrawerSubmittedImage={setDrawerSubmittedImage}
                  />
                  : <DrawerCategoryForm id={categoryId}
                                        setDrawerSubmitted={setDrawerSubmitted}
                                        setDrawerSubmittedStatus={setDrawerSubmittedStatus}
                                        setDrawerSubmittedName={setDrawerSubmittedName}
                                        setDrawerSubmittedImage={setDrawerSubmittedImage}
                  />
            )}
         </Drawer>

      </div>
   );
};

export default Menu;
