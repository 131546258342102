// src/components/FeedbackForm.js

import React, {useState, useEffect, useContext} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import config from "../../config.json";

const FeedbackForm = () => {
   const authCtx = useContext(AuthContext)
   const [id, setId] = useState();
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [date, setDate] = useState('');
   const [description, setDescription] = useState('');

   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/feedbacks/`

   const params = useParams();
   let navigate = useNavigate();


   const descriptionChangeHandler = (event) => {
      console.log('description: ' + event.target.value)
      setDescription(event.target.value);
   };

   useEffect(() => {
      const fetchCategory = async () => {
         if (params) {
            if (params['feedbackId']) {
               setId(params['feedbackId']);
               axios
                  .get(url + params['feedbackId'], {
                     withCredentials: true,
                     headers: {
                        Authorization: `Bearer ${authCtx.token}`
                     }
                  })
                  .then(response => {
                     console.log(response.data);
                     setName(response.data.name);
                     setEmail(response.data.email);
                     // const date = new Date(response.data.date_created);
                     setDate(new Date(response.data.date_created));
                     setDescription(response.data.content);
                  });
            } else {
               navigate("/feedbacks")
            }
         }
      }
      fetchCategory();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [params]);

   return (
      <div>
         <div className="px-4 py-5 space-y-2 sm:p-6">
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Ημερομηνία</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="text" value={date.toLocaleString("el-GR")} placeholder=""
                         className="input input-bordered input-primary w-full" disabled/>
               </div>
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Όνομα</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="text" value={name} placeholder=""
                         className="input input-bordered input-primary w-full" disabled/>
               </div>
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Email</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <input type="text" value={email} placeholder=""
                         className="input input-bordered input-primary w-full" disabled/>
               </div>
            </div>
            <div className="form-control w-full">
               <label className="label">
                  <span className="label-text">Περιγραφή</span>
               </label>
               <div className="mt-1 flex rounded-md shadow-sm">
                  <textarea className="textarea textarea-primary w-full max-w-4xl" rows="6"
                            value={((description) ? description : '')} disabled></textarea>
               </div>
            </div>
         </div>
         <div className="px-4 py-3 bg-base-200 bg-opacity-20 text-right sm:px-6">
            <Link className="btn btn-primary" to={'/feedbacks'}>ΕΠΙΣΤΡΟΦΗ</Link>
         </div>
      </div>
   );
};

export default FeedbackForm;