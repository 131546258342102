// src/components/Translations/TranslationsSettings.js

import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import jwtDecode from "jwt-decode";
import config from "../../config.json";

const Translations = () => {
   const authCtx = useContext(AuthContext)

   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/settings/`

   const title = 'Μεταφράσεις'

   const [settings, setSettings] = useState([]);
   const [formValues, setFormValues] = useState({footer_notes: '', language_code: ''});

   const fetchSettings = async () => {
      const {data} = await axios.get(url, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      const settings = data;
      setSettings(settings);
      console.log(settings);
   };

   const fetchTranslatedCategory = () => {
      axios.get(url + "translations", {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      }).then((response) => {
         console.log(response.data);
         const item = response.data;
         setFormValues({footer_notes: item.footer_notes, language_code: item.language_code});
         console.log(item);
      })
   };

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchSettings();
      fetchTranslatedCategory();
   }, [title, config])

   const handleFormInputChange = (event) => {
      const {name, value} = event.target;
      console.log(`Name: ${name}, Value: ${value}`);
      setFormValues((prevValues) => ({...prevValues, [name]: value}));
   };

   const handleFormSubmit = (event) => {
      event.preventDefault();
      const {footer_notes} = formValues;
      const payload = {footer_notes, language_code: 'en'};
      axios.post(url + `translations`,
         payload, {
            withCredentials: true,
            headers: {
               Authorization: `Bearer ${authCtx.token}`
            }
         }
      ).then((response) => {
         console.log(response.data);
      });
   };

   return (
      <>
         <div className="lg:grid grid-cols-4 pt-12 gap-12">
            <div className="lg:col-span-3">
               <form onSubmit={handleFormSubmit}>
                  <div className="overflow-x-auto">
                     <table className="overflow-x-scroll overscroll-contain mt-0">
                        <thead>
                        <tr>
                           <th
                              className="border border-separate p-4 min-w-[200px] font-bold bg-primary text-white rounded-tl-lg border-t-0 border-l-0 border-b-0">Ελληνικά
                           </th>
                           <th
                              className="border border-separate p-4 min-w-[200px] font-bold bg-primary text-white border-b-0">English
                           </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                           <td className="border border-separate border-l-2 p-4 min-w-[200px] max-w-[200px]">
                              <div className="text-xs opacity-60 pb-2">Footer Notes</div>
                              <div className="max-h-32 overflow-auto">{settings.footer_notes}</div>
                           </td>
                           <td className="border border-separate p-4 min-w-[200px] max-w-[200px]">
                              <div><label className="text-xs" htmlFor="footer_notes">Footer Notes</label></div>
                              <textarea value={formValues.footer_notes} onChange={handleFormInputChange}
                                        className="bg-base-100 text-primary placeholder:text-secondary w-full h-24 pt-1"
                                        placeholder="Footer Notes" name="footer_notes"></textarea>
                           </td>
                        </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="flex flex-row-reverse">
                     <button className="btn btn-primary" type="submit">ΑΠΟΘΗΚΕΥΣΗ</button>
                  </div>
               </form>
            </div>
         </div>
      </>
   );
};

export default Translations;