// src/components/NavBarThemeMenu.js

import React from 'react';
import IconTheme from "../../Icons/IconTheme";
import NavBarThemeComponent from "./NavBarThemeComponent";
import IconChevronDown from "../../Icons/IconChevronDown";


const NavBarThemeMenu = () => {

   const setTheme = (e) => {
      const theme = e.currentTarget.dataset['set_theme']
      const div = document.getElementById('root').querySelector("div");
      div.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
   }

   return (
      <div>
         <div title="Change Theme" className="dropdown dropdown-end">
            <div tabIndex="0" className="btn gap-1 normal-case btn-ghost">
               <IconTheme addClass={'inline-block h-5 w-5 stroke-current md:h-6 md:w-6'}/>
               <span className="hidden md:inline">Theme</span>
               <IconChevronDown addClass={'ml-1 hidden w-4 opacity-80 sm:inline-block'}/>
            </div>
            <div
               className="dropdown-content bg-base-200 text-base-content rounded-t-box rounded-b-box top-px max-h-96 h-[70vh] w-52 overflow-y-auto shadow-2xl mt-16">
               <div className="grid grid-cols-1 gap-3 p-3" tabIndex="0">
                  <NavBarThemeComponent theme={'default'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'light'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'dark'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'cupcake'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'bumblebee'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'emerald'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'corporate'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'synthwave'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'retro'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'cyberpunk'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'valentine'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'halloween'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'garden'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'forest'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'aqua'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'lofi'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'pastel'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'fantasy'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'wireframe'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'black'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'luxury'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'dracula'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'cmyk'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'autumn'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'business'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'acid'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'lemonade'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'night'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'coffee'} onClick={setTheme}/>
                  <NavBarThemeComponent theme={'winter'} onClick={setTheme}/>
               </div>
            </div>
         </div>
      </div>
   );
};

export default NavBarThemeMenu;