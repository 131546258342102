import React, {useContext, useEffect, useRef, useState} from 'react';
import {DndContext, closestCenter} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthContext from "../../store/auth-context";
import IconPlus from "../../components/Icons/IconPlus";
import config from "../../config.json"
import {errorToast, successToast} from "../../components/Layout/Toast";

const List = ({
                 children,
                 id,
                 categoryId,
                 items: defaultItems,
                 onReorder,
                 setIsDrawerOpen,
                 setIsCategory,
                 setIsProduct,
                 setCategoryId,
                 setProductId,
                 ...props
              }) => {

   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/categories/reorder`

   const [items, setItems] = useState(defaultItems);
   const [sort, setSort] = useState([]);
   const [opacity, setOpacity] = useState(false);

   const notInitialRender = useRef(false);

   const handleDragEnd = ({active, over}) => {
      if (!over) {
         return;
      }
      console.log(active.id);
      console.log(over.id);
      if (active.id !== over.id) {
         // const activeIndex = active.data.current.sortable.index;
         // const overIndex = over.data.current?.sortable.index || 0;
         // setItems((items) => {
         const oldIndex = items.findIndex((item) => item.id === active.id);
         const newIndex = items.findIndex((item) => item.id === over.id);
         let newItems = arrayMove(items, oldIndex, newIndex);
         console.log(newItems);
         postSortCategory(newItems);
         // return newItems
         // });
      }
   };

   const postSortCategory = async (newItems) => {
      console.log('--- Sort ---')
      setOpacity(true)
      setSort([]);
      for (let i = 0; i < newItems.length; i++) {
         sort.push({sort: i, id: newItems[i]['id']});
      }
      console.log(sort);
      console.log('--- end Sort ---')
      let data = {"data": sort}
      axios.post(url, data, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
         .then(function (response) {
            setOpacity(false);
            onReorder(newItems);
            successToast('Οι αλλαγές αποθηκεύτηκαν!');
         })
         .catch(function (error) {
            console.log(error);
            setOpacity(false);
            errorToast('Οι αλλαγές δεν αποθηκεύτηκαν');
         });
   }

   useEffect(() => setItems(defaultItems), [defaultItems]);

   useEffect(() => {
      if (notInitialRender.current && onReorder) {
         onReorder(items);
      } else {
         notInitialRender.current = true;
      }
   }, []);
   // }, [items]);  //ERROR

   return (
      <DndContext modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
         <div className="card bg-base-100 shadow-xl border-solid border-2 border-base-200 my-4">
            <button
               className="flex items-center cursor-pointer px-4 py-4"
               onClick={() => {
                  setIsDrawerOpen(true);
                  setIsProduct(false);
                  setIsCategory(true)
                  setCategoryId("")
                  setProductId("")
               }}
            >
               <IconPlus addClass="w-4 h-4 fill-current opacity-80 mr-1"/>Προσθήκη Κατηγορίας
            </button>
         </div>
         <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
            <ul className={"flex flex-col space-y-2" + (opacity ? " opacity-30" : "")} {...props}>{children}</ul>
         </SortableContext>
      </DndContext>
   );
};

export default List;
