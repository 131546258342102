// src/components/Feedbacks.js

import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import IconHome from "../../components/Icons/IconHome";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import jwtDecode from "jwt-decode";
import config from "../../config.json";
import FeedbacksTableRow from "./FeedbacksTableRow";

const Feedbacks = () => {
   const authCtx = useContext(AuthContext)
   const [feedbacks, setFeedbacks] = useState([]);

   const decoded = jwtDecode(authCtx.token)
   const url = `${config.API_URL}/tenants/${decoded.tenant_id}/feedbacks/`

   const title = 'Αξιολογήσεις'

   const fetchFeedbacks = async () => {
      const {data} = await axios.get(url, {
         withCredentials: true,
         headers: {
            Authorization: `Bearer ${authCtx.token}`
         }
      })
      const feedbacks = data;
      setFeedbacks(feedbacks);
      console.log(feedbacks);
   };

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
      fetchFeedbacks();
   }, [title, config])

   return (
      <div className="px-6 pb-16">
         <div className="max-w-full text-sm font-medium breadcrumbs py-6">
            <ul>
               <li>
                  <Link to="/dashboard" className="hover:text-primary">
                     <IconHome/>
                     Αρχική
                  </Link>
               </li>
               <li>
                  {title}
               </li>
            </ul>
         </div>
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-4xl flex-grow">
               <h1>{title}</h1>
               <div className="overflow-x-auto">
                  <table className="table w-full mt-0">
                     <thead>
                     <tr>
                        <th>#</th>
                        <th>Στοιχεία</th>
                        <th>Ημερομηνία</th>
                        <th>Ενέργεια</th>
                     </tr>
                     </thead>
                     <tbody>
                     {feedbacks.map((item) => (
                        <FeedbacksTableRow key={item.id} id={item.id} name={item.name} email={item.email}
                                           date={item.date_created}/>
                     ))}
                     {(() => {
                        if (!feedbacks.length) {
                           return (
                              <tr className="hover">
                                 <td className="text-center" colSpan="4">Δεν υπάρχουν αξιολογήσεις</td>
                              </tr>
                           )
                        }
                     })()}


                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Feedbacks;