import React, {useState, useContext} from 'react';
import {
   BrowserRouter,
   Routes,
   Route,
   Navigate,
   useParams
} from "react-router-dom";

import Layout from './components/Layout/Layout';
import Dashboard from './pages/Dashboard';
// import Products from "./pages/Products/Products";
// import Product from "./pages/Products/Product";
// import Categories from "./pages/Categories/Categories";
// import Category from "./pages/Categories/Category";
import Menu from "./pages/Menu/Menu";
import Feedbacks from './pages/Feedbacks/Feedbacks';
import Feedback from './pages/Feedbacks/Feedback';
import Translations from './pages/Translations/Translations';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Theme from './pages/Theme/Theme';
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import ResetPass from "./pages/ResetPass";

import AuthContext from './store/auth-context';

function App() {
   const authCtx = useContext(AuthContext);

   const [theme, setTheme] = useState(localStorage.getItem("theme"));

   let {userId} = useParams();
   let {productId} = useParams();
   let {categoryId} = useParams();
   let {FeedbackId} = useParams();
   let {tokenId} = useParams();

   return (
      <div data-theme={theme}>
         <BrowserRouter>
            {authCtx.isLoggedIn && (
               <Layout>
                  <Routes>
                     <Route path='/' element={ <Navigate to="/dashboard" replace={true} /> }/>
                     <Route path="/dashboard" element={<Dashboard/>}/>
                     <Route path='/menu' element={<Menu/>}/>
                     {/*<Route path='/products' element={<Products/>}/>*/}
                     {/*<Route path='/products/add' element={<Product/>}/>*/}
                     {/*<Route path='/products/:productId' element={<Product/>}/>*/}
                     {/*<Route path='/categories' element={<Categories/>}/>*/}
                     {/*<Route path='/categories/add' element={<Category/>}/>*/}
                     {/*<Route path='/categories/:categoryId' element={<Category/>}/>*/}
                     <Route path='/feedbacks' element={<Feedbacks/>}/>
                     <Route path='/feedbacks/:feedbackId' element={<Feedback/>}/>
                     <Route path='/translations' element={<Translations/>}/>
                     <Route path='/profile' element={<Profile/>}/>
                     <Route path='/settings' element={<Settings/>}/>
                     <Route path='/theme' element={<Theme/>}/>
                  </Routes>
               </Layout>
            )}
            <Routes>
               <Route path='/login' element={<Login/>}/>
               {!authCtx.isLoggedIn && (<Route path='*' element={<Login/>}/>)}
               <Route path='/forgot-password' element={<ForgotPass/>}/>
               <Route path='/reset-password/:tokenId' element={<ResetPass/>}/>
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
